<template>
  <div class="homeview">
    <Loader :loading="showLoader" />
    <v-toolbar color="#222222" flat dense class="mb-4">
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title
        style="font-family: 'Material Design Icons'; font-size: 25px !important"
      >
        {{ 'Analytics' }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-container fluid class="py-6">
      <v-row justify="center">
        <v-col cols="12" md="6" lg="3" v-for="card in cards" :key="card.id">
          <v-card
            class="mx-auto my-4"
            :hover="true"
            :elevation="3"
            outlined
            @click="onCardClick(card)"
          >
            <div class="card-image">
              <img :src="card.image" alt="Card image" />
            </div>
            <v-card-title>{{ card.title }}</v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { ref, defineComponent, onMounted } from 'vue'
  import Loader from '@/components/general/Loader.vue'
  import { getToolbarLogoImage } from '@/assets/Branding/branding.js'

  export default defineComponent({
    name: 'AnalyticsList',
    components: {
      Loader,
    },
    setup() {
      const logo = ref('')
      const showLoader = ref(false)
      const cards = ref([
        {
          id: 1,
          title: 'Energy Consumption',
          image: require('@/assets/General/energy_graph_animated.gif'),
        },
        {
          id: 2,
          title: 'Office Days',
          image: require('@/assets/General/performance_chart.jpg'),
        },
        {
          id: 3,
          title: 'App Usage',
          image: require('@/assets/General/app_usage_chart.jpg'),
        },
        {
          id: 4,
          title: 'Card 4',
          image: require('@/assets/General/energy_graph.png'),
        },
      ])

      onMounted(async () => {
        try {
          logo.value = await getToolbarLogoImage()
          showLoader.value = true
        } catch (error) {
          console.error(error)
        } finally {
          showLoader.value = false
        }
      })

      const onCardClick = card => {
        console.log(`Card ${card.id} clicked!`)
      }

      return {
        logo,
        showLoader,
        cards,
        onCardClick,
      }
    },
  })
</script>

<style scoped>
  .toolbar-icon {
    height: 30px;
    align-self: center;
    margin-left: 1rem;
  }
  .v-card {
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  .v-card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .v-card-title {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    padding: 16px 0;
  }
  .card-image {
    height: 200px;
    overflow: hidden;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
</style>
