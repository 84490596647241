<template>
  <div class="homeview">
    <Loader :loading="showLoader" />
    <v-toolbar color="#222222" flat dense class="mb-4">
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title
        style="font-family: 'Material Design Icons'; font-size: 25px !important"
      >
        {{ 'Contacts' }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-container fluid class="py-6">
      <div class="search-bar-container">
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Search by name, email, or job"
          class="search-bar"
        />
      </div>
      <div class="custom-list">
        <div
          v-for="(item, index) in filteredUsers"
          :key="index"
          class="custom-list-item"
          :class="{ disabled: item.email === ownUser.email }"
          @click="item.email !== ownUser.email && openChat(item)"
        >
          <template v-if="item.type === 'subheader'">
            <div class="subheader">{{ item.title }}</div>
          </template>
          <template v-else-if="item.type === 'divider'">
            <hr class="divider" />
          </template>
          <template v-else>
            <img :src="item.prependAvatar" class="avatar" />
            <div class="content">
              <div class="title" v-html="item.title"></div>
              <div class="subtitle" v-html="item.subtitle"></div>
            </div>
            <v-icon
              v-if="item.email !== ownUser.email"
              @click="toggleFavorite(item)"
              class="favorite-icon"
            >
              {{ item.isFavorite ? 'mdi-star' : 'mdi-star-outline' }}
            </v-icon>
          </template>
        </div>
      </div>
    </v-container>

    <v-snackbar v-model="snackbar" timeout="3000" top>
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
  import { ref, defineComponent, onMounted, computed, watch } from 'vue'
  import { debounce } from 'lodash'
  import Loader from '@/components/general/Loader.vue'
  import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
  import { getUsers } from '@/controllers/BackboneAPI'
  import {
    getUserProfiles,
    getFile,
    getDataFromBlob,
  } from '@/controllers/BaseController'
  import lodash from 'lodash'
  import user_avatar from '@/assets/General/user_avatar.png'
  import {
    getStoredItem,
    setStoredItem,
    removeStoredItem,
  } from '@/services/utils'
  import { useRouter } from 'vue-router'
  import { useOAuthService } from '@/services/OAuthService'

  export default defineComponent({
    name: 'ChatUsersList',
    components: {
      Loader,
    },
    setup() {
      const logo = ref('')
      const OAuthService = useOAuthService()
      const showLoader = ref(false)
      const users = ref([])
      const ownUser = ref(null)
      const searchQuery = ref('')
      const debouncedSearchQuery = ref('')
      const snackbar = ref(false)
      const snackbarText = ref('')
      const router = useRouter()

      const openChat = async user => {
        await setStoredItem('selectedUser', user)
        await setStoredItem(
          'ownUser',
          JSON.parse(JSON.stringify(ownUser.value))
        )
        router.push({
          name: 'Chat',
          params: { name: user.title, email: user.email },
        })
      }

      const filteredUsers = computed(() => {
        const query = debouncedSearchQuery.value.toLowerCase()
        return users.value
          .filter(
            user =>
              user.title.toLowerCase().includes(query) ||
              user.subtitle.toLowerCase().includes(query) ||
              (user.profile?.job?.toLowerCase().includes(query) ?? false)
          )
          .sort((a, b) => {
            if (a.isFavorite && !b.isFavorite) return -1
            if (!a.isFavorite && b.isFavorite) return 1
            return a.title.localeCompare(b.title)
          })
      })

      const updateSearchQuery = debounce(query => {
        debouncedSearchQuery.value = query
      }, 300)

      watch(searchQuery, newQuery => {
        updateSearchQuery(newQuery)
      })

      const toggleFavorite = async user => {
        user.isFavorite = !user.isFavorite
        if (user.isFavorite) {
          await setStoredItem(user.email, true)
          snackbarText.value = `${user.title} added to favorites`
        } else {
          await removeStoredItem(user.email)
          snackbarText.value = `${user.title} removed from favorites`
        }
        snackbar.value = true
        sortUsers()
      }

      const sortUsers = () => {
        users.value = users.value.slice().sort((a, b) => {
          if (a.isFavorite && !b.isFavorite) return -1
          if (!a.isFavorite && b.isFavorite) return 1
          return a.title.localeCompare(b.title)
        })
      }

      onMounted(async () => {
        try {
          logo.value = await getToolbarLogoImage()
          showLoader.value = true

          const [usersList, userProfiles] = await Promise.all([
            getUsers(),
            getUserProfiles(),
          ])

          const formattedUsers = await Promise.all(
            usersList.map(async user => {
              const email = user?.email
              const profile = userProfiles.find(
                profile => profile?.email === email
              )

              const title =
                !user?.firstName ||
                !user?.lastName ||
                user?.firstName.toLowerCase() === 'firstname' ||
                user?.lastName.toLowerCase() === 'lastname'
                  ? email
                      .split('@')[0]
                      .replace(/\./g, ' ')
                      .replace(/\b\w/g, char => char.toUpperCase())
                  : `${user?.firstName} ${user?.lastName}`

              const subtitle = profile?.job
                ? `${email}<br><span class="text-primary">Position</span> &mdash; ${profile.job}`
                : email

              let prependAvatar = user_avatar
              if (profile) {
                const profileImage = profile?.files?.entities.find(
                  file => file.type === 'profileImage'
                )
                if (profileImage) {
                  const fileData = await getFile(profileImage.id)
                  const base64ImageData = await getDataFromBlob(fileData)
                  if (base64ImageData.startsWith('data:image')) {
                    prependAvatar = base64ImageData
                  }
                }
              }

              const isFavorite = (await getStoredItem(email)) === true

              return {
                ...user,
                profile,
                title,
                subtitle,
                prependAvatar,
                isFavorite,
              }
            })
          )
          let ownUserProfile = await OAuthService.getUserProfile()
          ownUser.value = formattedUsers.find(
            user => user.email === ownUserProfile.email
          )
          users.value = formattedUsers
          sortUsers()
        } catch (error) {
          console.error(error)
        } finally {
          showLoader.value = false
        }
      })

      return {
        logo,
        users,
        showLoader,
        searchQuery,
        filteredUsers,
        snackbar,
        snackbarText,
        toggleFavorite,
        openChat,
        ownUser,
      }
    },
  })
</script>

<style scoped>
  .toolbar-icon {
    height: 30px;
    align-self: center;
    margin-left: 1rem;
  }

  .search-bar-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .search-bar {
    width: 100%;
    max-width: 600px;
    padding: 0.5rem 1rem;
    border-radius: 50px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    font-size: 1rem;
  }

  .search-bar:focus {
    outline: none;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .custom-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .custom-list-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 1rem;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .custom-list-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .custom-list-item.disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 1rem;
  }

  .content {
    flex: 1;
  }

  .title {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .subtitle {
    color: #555;
  }

  .subheader {
    font-size: 1.4rem;
    font-weight: bold;
    padding: 0.5rem 0;
    color: #333;
  }

  .divider {
    height: 1px;
    background-color: #ccc;
    margin: 1rem 0;
  }

  .favorite-icon {
    cursor: pointer;
    margin-left: auto;
    color: #ffd700;
  }

  .v-snackbar {
    bottom: 50%;
  }
</style>
