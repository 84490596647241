<template>
  <div class="homeview">
    <Loader :loading="showLoader" />
    <v-toolbar color="#222222" flat dense class="mb-4">
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title
        style="font-family: 'Material Design Icons'; font-size: 25px !important"
      >
        {{ 'I N F O' }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-container fluid class="py-6">
      <v-row justify="center">
        <v-col
          v-for="infoItem in infoItems"
          :key="infoItem.id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <div
            class="info-card-container"
            :class="{ 'disabled-card': isDisabled(infoItem.title) }"
            @mouseover="
              hoveredCard = isDisabled(infoItem.title) ? null : infoItem.id
            "
            @mouseleave="hoveredCard = null"
            @click="
              isDisabled(infoItem.title) ? null : infoItemDetail(infoItem)
            "
          >
            <img
              :src="infoItem.image"
              class="info-img"
              :class="{ 'info-img-hover': hoveredCard === infoItem.id }"
            />
            <div class="card-content">
              <v-chip class="info-chip">
                {{ infoItem.title }}
              </v-chip>
              <span v-if="isDisabled(infoItem.title)" class="coming-soon">
                im Ausbau
              </span>
              <button
                v-else
                class="explore-btn"
                @click="infoItemDetail(infoItem)"
              >
                <i class="mdi mdi-arrow-right"></i>
                Ansehen
              </button>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { ref, defineComponent, onMounted } from 'vue'
  import moment from 'moment'
  import Loader from '@/components/general/Loader.vue'
  import { getHomeScreenData } from '@/assets/Branding/Artinvest/Data/MarketingData'
  import { getToolbarLogoImage } from '@/assets/Branding/branding.js'

  import { useRouter } from 'vue-router'

  export default defineComponent({
    name: 'MarketingView',
    components: {
      Loader,
    },
    setup() {
      const hoveredCard = ref(null)
      const showLoader = ref(true)
      const customerName = ref('')
      const logo = ref('')
      const infoItems = ref([])
      const router = useRouter()
      const disabledItems = ['SHOWROOM', 'Live-Webcams', 'Art-Invest Team']

      const isDisabled = title => {
        return disabledItems.includes(title)
      }

      const infoItemDetail = infoItem => {
        try {
          let selectedItem = JSON.parse(JSON.stringify(infoItem))
          if (selectedItem) {
            let selectedItemName = selectedItem.title

            switch (selectedItemName) {
              case 'ART-INVEST':
                router
                  .push({ name: 'ArtInvestView' })
                  .catch(err => console.error('Routing error:', err))
                break
              case 'ALTER WALL':
                console.log('Selected: OLD WALL')
                router
                  .push({ name: 'OldWallView', hash: '#oldwall232' })
                  .catch(err => console.error('Routing error:', err))
                break
              case 'SHOWROOM':
              case 'HOTELS':
                console.log('Selected: Hotels')
                router
                  .push({ name: 'HotelView' })
                  .catch(err => console.error('Routing error:', err))
                break
              case 'DIGITALISIERUNG':
                console.log('Selected: Digitalization')
                router
                  .push({ name: 'DigitalizationView' })
                  .catch(err => console.error('Routing error:', err))
                break
              case 'Live-Webcams':
              case 'Art-Invest Team':
                // No action needed as these are disabled
                break
              case 'ALTER WALL GRUNDRISSE':
                console.log('Selected: ALTER WALL GRUNDRISSE')
                // router.push({ name: "RestaurantsView" }).catch((err) => console.error("Routing error:", err));
                break
              case 'RESTAURANTS':
                console.log('Selected: RESTAURANTS')
                router
                  .push({ name: 'RestaurantsView' })
                  .catch(err => console.error('Routing error:', err))
                break
              case 'ÖPNV':
                router
                  .push({ name: 'VeomoView' })
                  .catch(err => console.error('Routing error:', err))
                break
              default:
                console.log('Selected item does not match any case')
            }
          }
        } catch (error) {
          console.error('Error in infoItemDetail:', error)
        }
      }

      onMounted(async () => {
        let infoItemsData = await getHomeScreenData()
        infoItems.value = infoItemsData
        logo.value = await getToolbarLogoImage()

        setTimeout(() => {
          showLoader.value = false
        }, 1200)
      })

      return {
        hoveredCard,
        infoItems,
        infoItemDetail,
        showLoader,
        logo,
        isDisabled,
      }
    },
  })
</script>

<style scoped>
  .homeview {
    padding-bottom: 70px;
  }

  .info-card-container {
    transition: box-shadow 0.3s ease-in-out;
    cursor: pointer;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  }

  .info-card-container:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .disabled-card {
    opacity: 0.5;
    pointer-events: none;
  }

  .card-content {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: start; /* Align items to the start (left side for LTR languages) */
    gap: 8px; /* Space between chip and button */
    padding: 16px;
    background: #fff;
    text-align: center;
  }

  .info-chip {
    font-family: 'Material Design Icons';
    font-weight: bold;
    color: #333;
    margin-bottom: 8px; /* Ensures space between the title and button */
  }

  .info-title {
    font-family: 'Material Design Icons';
    font-size: 20px; /* Reduced size for elegance */
    font-weight: normal; /* Less boldness for a lighter appearance */
    color: #333; /* Keep the color dark for contrast */
    margin: 8px 0 16px 0; /* Adjust the margin */
    line-height: 1.25; /* Adjust line height for better readability */
  }

  .explore-btn {
    padding: 6px 12px; /* Reduced padding for a smaller button */
    background-color: #077a2d; /* Attractive shade of blue */
    border: none;
    border-radius: 2px; /* Less rounded corners */
    color: white;
    font-size: 14px; /* Smaller font size */
    text-transform: none; /* Avoid uppercase for a more elegant look */
    font-weight: bold;
    letter-spacing: normal;
    transition: all 0.3s ease;
    outline: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin-top: 8px; /* Add some margin to the top */
  }

  .explore-btn i,
  .explore-btn .v-icon {
    /* Adjust for your specific icon component */
    font-size: 18px; /* Larger size for the icon */
    margin-right: 8px;
  }

  .explore-btn:hover {
    background-color: black; /* Lighter blue for hover */
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    transform: translateY(-2px);
  }
  .explore-btn v-icon {
    margin-right: 8px; /* Space between icon and text */
  }

  .toolbar-icon {
    height: 30px;
    align-self: center;
    margin-left: 1rem;
  }

  .v-card-title {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: white;
    font-size: 15px;
  }

  .info-img {
    width: 100%;
    height: auto;
    border-radius: 12px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    cursor: pointer;
    object-fit: cover; /* Adjust as needed to handle image aspect ratio */
    aspect-ratio: 1.7; /* This can also be controlled within the style if necessary */
  }

  .info-img-hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .coming-soon {
    color: red;
    font-size: 16px;
    font-weight: bold;
  }

  .read-more-btn {
    font-family: 'Roboto', sans-serif;
    color: #1976d2;
    text-transform: none;
    font-weight: bold;
  }

  .read-more-btn v-icon {
    margin-left: 4px;
  }

  @media (max-width: 600px) {
    .info-img {
      border-radius: 12px 12px 0 0; /* rounded corners at the top only */
    }

    .card-content {
      padding: 8px;
    }
  }
</style>
