<template>
  <div class="background">
    <Loader :loading="showLoader" />
    <v-toolbar color="#222222" dense flat>
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title>Room Control</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-container>
      <v-row justify="center" class="icon-row">
        <v-col cols="3" class="icon-col" v-if="hasLight" @click="setActive(1)">
          <div
            :class="{
              'active-icon': activeIcon === 1,
              'inactive-icon': activeIcon !== 1,
            }"
            class="icon-container"
          >
            <v-icon :class="{ 'small-icon': isSmallScreen }">
              mdi-lightbulb-outline
            </v-icon>
          </div>
        </v-col>
        <v-col cols="3" class="icon-col" v-if="hasBlinds" @click="setActive(2)">
          <div
            :class="{
              'active-icon': activeIcon === 2,
              'inactive-icon': activeIcon !== 2,
            }"
            class="icon-container"
          >
            <v-icon :class="{ 'small-icon': isSmallScreen }">mdi-blinds</v-icon>
          </div>
        </v-col>
        <v-col
          cols="3"
          class="icon-col"
          v-if="hasVentilator"
          @click="setActive(3)"
        >
          <div
            :class="{
              'active-icon': activeIcon === 3,
              'inactive-icon': activeIcon !== 3,
            }"
            class="icon-container"
          >
            <v-icon :class="{ 'small-icon': isSmallScreen }">mdi-fan</v-icon>
          </div>
        </v-col>
        <v-col cols="3" class="icon-col" v-if="hasHeater" @click="setActive(4)">
          <div
            :class="{
              'active-icon': activeIcon === 4,
              'inactive-icon': activeIcon !== 4,
            }"
            class="icon-container"
          >
            <v-icon :class="{ 'small-icon': isSmallScreen }">
              mdi-thermometer
            </v-icon>
          </div>
        </v-col>
      </v-row>
      <LightControl :light="lights" v-if="activeIcon === 1" />
      <BlindsControl :blinds="blinds" v-if="activeIcon === 2" />
      <vent-control :vents="ventilators" v-if="activeIcon === 3"></vent-control>
      <HeatingControl :heaters="heaters" v-if="activeIcon === 4" />
    </v-container>
  </div>
</template>

<script>
  import { defineComponent, ref, onMounted, onUnmounted } from 'vue'
  import Loader from '@/components/general/Loader.vue'
  import LightControl from '@/components/general/LightControl.vue'
  import VentControl from '@/components/general/VentControl.vue'
  import HeatingControl from '@/components/general/HeatingControl.vue'
  import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
  import { useRoute } from 'vue-router'
  import { getStoredItem } from '@/services/utils.js'
  import { loadConfig } from '@/configLoader'
  import { getHardwareByType } from '@/controllers/BackboneAPI'
  import { getPoiById } from '@/controllers/BaseController'
  import BlindsControl from '@/components/general/BlindsControl.vue'
  import lodash from 'lodash'
  import mqttService from '@/services/mqtt.js'
  import {
    lightsTest,
    ventilationTest,
    blindsTest,
    heatersTest,
  } from '@/tests/roomControlHardwares'

  export default defineComponent({
    name: 'RoomControl',
    components: {
      Loader,
      LightControl,
      BlindsControl,
      VentControl,
      HeatingControl,
    },
    setup() {
      const logo = ref()
      const isSimulation = ref(false)
      const showLoader = ref(false)
      const activeIcon = ref(undefined)
      const isSmallScreen = ref(false)
      const route = useRoute()
      const hardwares = ref([])
      const hasLight = ref(false)
      const hasBlinds = ref(false)
      const hasVentilator = ref(false)
      const hasHeater = ref(false)
      const lights = ref([])
      const blinds = ref([])
      const ventilators = ref([])
      const heaters = ref([])

      const setActive = iconNumber => {
        activeIcon.value = iconNumber
      }

      const checkScreenSize = () => {
        isSmallScreen.value = window.innerWidth < 600
      }

      const fetchHardwares = async poi => {
        try {
          const storedHardwares = await getStoredItem('selectedHardwares')
          if (!storedHardwares) {
            console.error('No hardwares found in storage')
            return []
          }

          const hardwareTypes = ['LIGHT', 'BLINDS', 'VENTILATOR', 'HEATER']
          const hardwareFetches = hardwareTypes.map(async type => {
            const hardwaresByType = await getHardwareByType(
              poi?.buildingId,
              type
            )
            // hardwaresByType.forEach(hardware => {
            //   let actions = hardware.actions
            //   if (hardware.hardwareType === 'BLINDS') {
            //     console.log(hardware)
            //     // actions.forEach(action => {
            //     //   if (action.actionType === 'scene') {
            //     //     console.log(hardware)
            //     //   }
            //     // })
            //   }
            // })
            return storedHardwares
              .filter(hardware => hardware.type === type)
              .map(hardware =>
                lodash.find(hardwaresByType, { id: hardware.id })
              )
          })

          const hardwareResults = await Promise.all(hardwareFetches)
          return hardwareResults.flat()
        } catch (error) {
          console.error('Error fetching hardwares:', error)
          return []
        }
      }

      onMounted(async () => {
        try {
          const [logoImg, config] = await Promise.all([
            getToolbarLogoImage(),
            loadConfig(),
          ])
          logo.value = logoImg

          showLoader.value = true
          checkScreenSize()
          window.addEventListener('resize', checkScreenSize)

          await mqttService.connect()

          const poiId = route.params.poi_id
          if (!poiId) {
            console.error('No poi_id found in route parameters')
            return
          }

          const poi = await getPoiById(poiId)
          let poiHardwares = await fetchHardwares(poi)
          if (poiHardwares.length === 0) {
            console.error('No hardwares found for POI')
            return
          }

          isSimulation.value = true
          if (isSimulation.value) {
            poiHardwares = []
            poiHardwares = [
              ...lightsTest,
              ...ventilationTest,
              ...blindsTest,
              ...heatersTest,
            ]
          }

          const types = poiHardwares.map(hardware => hardware.hardwareType)

          hasLight.value = types.includes('LIGHT')
          hasBlinds.value = types.includes('BLINDS')
          hasVentilator.value = types.includes('VENTILATOR')
          hasHeater.value = types.includes('HEATER')
          hardwares.value = poiHardwares

          if (hasLight.value) {
            const lightsHardware = poiHardwares.filter(
              hardware => hardware.hardwareType === 'LIGHT'
            )

            lightsHardware.forEach((lightHardware, index) => {
              const actions = lightHardware.actions
              const updatedHardware = { ...lightHardware }

              actions.forEach(action => {
                if (action.actionType === 'scene') {
                  updatedHardware.hasScene = true
                  updatedHardware.sceneOptions = action.spec.def
                } else if (action.actionType === 'brightness') {
                  updatedHardware.brightnessOptions = action.spec.def[0]
                  updatedHardware.hasBrightness = true
                } else if (action.actionType === 'temperature') {
                  updatedHardware.temperatureOptions = action.spec.def[0]
                  updatedHardware.hasTemperature = true
                } else if (action.actionType === 'switch') {
                  updatedHardware.switchOptions = action.spec.def
                  updatedHardware.hasSwitch = true
                }
              })

              lightsHardware[index] = updatedHardware
            })
            lights.value = lightsHardware
          }

          if (hasBlinds.value) {
            const blindsHardware = poiHardwares.filter(
              hardware => hardware.hardwareType === 'BLINDS'
            )
            blindsHardware.forEach(blind => {
              let actions = blind.actions
              actions.forEach(action => {
                if (action.actionType === 'switch') {
                  blind.hasSwitch = true
                  blind.switchOptions = action.spec.def[0]
                } else if (action.actionType === 'position') {
                  blind.hasPosition = true
                  blind.positionOptions = action.spec.def[0]
                } else if (action.actionType === 'angle') {
                  blind.hasAngle = true
                  blind.angleOptions = action.spec.def
                } else if (action.actionType === 'scene') {
                  blind.hasScene = true
                  blind.sceneOptions = action.spec.def
                }
              })
            })
            blinds.value = blindsHardware
          }
          if (hasVentilator.value) {
            const ventilatorHardware = poiHardwares.filter(
              hardware => hardware.hardwareType === 'VENTILATOR'
            )
            let vents = []
            ventilatorHardware.forEach(ventilator => {
              let actions = ventilator.actions
              actions.forEach(action => {
                if (action.actionType === 'motor') {
                  ventilator.hasMotor = true
                  let defs = action.spec.def
                  let index = 0
                  ventilator.ventOptions = []
                  for (let def in defs) {
                    let action = {
                      id: index++,
                      value: def,
                      label: def,
                      hwValue: defs[def].value,
                    }
                    ventilator.ventOptions.push(action)
                  }
                  vents.push(ventilator)
                }
              })
            })
            ventilators.value = vents
          }
          if (hasHeater.value) {
            const heaterHardware = poiHardwares.filter(
              hardware => hardware.hardwareType === 'HEATER'
            )
            let heatersHardwares = []
            heaterHardware.forEach(heater => {
              let actions = heater.actions
              actions.forEach(action => {
                if (action.actionType === 'temperature') {
                  heater.hasTemperature = true
                  heater.temperatureOptions = action.spec.def[0]
                }
                heatersHardwares.push(heater)
              })
            })
            heaters.value = heatersHardwares
          }

          if (types.includes('LIGHT')) {
            setActive(1)
          } else if (types.includes('BLINDS')) {
            setActive(2)
          } else if (types.includes('VENTILATOR')) {
            setActive(3)
          } else if (types.includes('HEATER')) {
            setActive(4)
          }
        } catch (error) {
          console.error('Error processing POI:', error)
        } finally {
          showLoader.value = false
          //test//
          setActive(1)
        }
      })

      onUnmounted(() => {
        window.removeEventListener('resize', checkScreenSize)
        mqttService.disconnect()
      })

      return {
        logo,
        showLoader,
        activeIcon,
        setActive,
        isSmallScreen,
        hardwares,
        hasLight,
        hasBlinds,
        hasVentilator,
        hasHeater,
        lights,
        blinds,
        ventilators,
        heaters,
      }
    },
  })
</script>

<style scoped>
  .background {
    background-color: #ffffff;
  }

  .toolbar-icon {
    height: 30px;
    align-self: center;
    margin-left: 1rem;
  }

  .icon-row {
    margin-top: 20px;
  }

  .icon-col {
    text-align: center;
  }

  .icon-container {
    display: inline-block;
    border-radius: 50%;
    padding: 10px;
    font-size: 36px;
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  .icon-container:hover {
    transform: scale(1.1);
  }

  .active-icon {
    color: #ffffff;
    background-color: black;
  }

  .inactive-icon {
    color: #ffffff;
    background-color: #888888;
  }

  .small-icon {
    font-size: 35px !important;
  }

  @media (max-width: 600px) {
    .icon-container {
      padding: 5px;
      font-size: 24px;
    }

    .toolbar-icon {
      height: 20px;
    }
  }
</style>
