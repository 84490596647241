<template>
  <div class="infocenter">
    <Loader :loading="showLoader" />
    <v-toolbar
      flat
      dense
      class="mb-4"
      :style="{
        background: 'linear-gradient(135deg, #222222, #444444)',
        color: '#ffffff',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
        padding: '16px',
      }"
    >
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title
        class="ml-3"
        :style="{
          fontFamily: 'Poppins, sans-serif',
          fontWeight: '600',
          fontSize: '1.5rem',
          color: '#ffffff',
        }"
      ></v-toolbar-title>
    </v-toolbar>

    <v-container fluid class="py-6">
      <v-row justify="center">
        <v-col
          v-for="infoItem in infoItems"
          :key="infoItem.id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-card
            class="info-card"
            :class="{ 'info-card-hover': hoveredCard === infoItem.id }"
            @mouseover="hoveredCard = infoItem.id"
            @mouseleave="hoveredCard = null"
            @click="infoItemDetail(infoItem)"
          >
            <v-img
              :src="infoItem.image ? infoItem.image : fallbackImage"
              class="white--text align-end"
              aspect-ratio="1.7"
            >
              <!-- Placeholder is shown while the image is loading -->
              <template #placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            =
            <v-card-title style="white-space: break-spaces" color="black">
              {{ infoItem.title }}
            </v-card-title>
            <v-card-text class="text-description">
              {{ infoItem.shortText }}
            </v-card-text>
            <v-card-subtitle color="black" style="color: black !important">
              {{ infoItem.creationTime }}
            </v-card-subtitle>

            <v-card-actions v-if="infoItem?.buttonText">
              <v-btn
                class="read-more-btn"
                density="compact"
                @click="infoItemDetail(infoItem)"
                variant="tonal"
              >
                {{ infoItem?.buttonText || 'Read More' }}
                <v-icon right>mdi-chevron-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <InfoItemDetails
        ref="infoItemDetailDialogRef"
        :infoItem="selectedInfoItem"
      />
    </v-container>
  </div>
</template>

<script>
  import { ref, defineComponent, onMounted } from 'vue'
  import moment from 'moment'
  import lodash from 'lodash'
  import Loader from '@/components/general/Loader.vue'
  import InfoItemDetails from '@/components/general/InfoItemDetails.vue'
  import {
    getToolbarLogoImage,
    getToolBarCustomerName,
  } from '@/assets/Branding/branding.js'
  import {
    getInfoItems,
    getInfoCategoryById,
    getFile,
    getDataFromBlob,
    getFileInfo,
    readAsDataURL,
  } from '@/controllers/BaseController'

  export default defineComponent({
    name: 'InfoCenterView',
    components: {
      Loader,
      InfoItemDetails,
    },
    setup() {
      const hoveredCard = ref(null)
      const showLoader = ref(true)
      const customerName = ref('')
      const logo = ref('')
      const fallbackImage = ref('')
      const infoItems = ref([])
      const selectedInfoItem = ref({}) // Initialize with an empty object
      const infoItemDetailDialogRef = ref(null)

      const infoItemDetail = infoItem => {
        infoItem = { ...infoItem }
        selectedInfoItem.value = infoItem
        if (infoItem?.text === '') {
          if (infoItem.buttonUrl !== '') {
            window.open(infoItem.buttonUrl, '_blank')
          }
        } else {
          infoItemDetailDialogRef.value.openInfoItemDetail(infoItem)
        }
      }

      const getBase64Images = async (entities, type) => {
        const filteredEntities = lodash.filter(
          entities,
          item => item.type === type
        )
        return await Promise.all(
          filteredEntities.map(async entity => {
            const file = await getFile(entity?.id)
            return readAsDataURL(file)
          })
        )
      }
      onMounted(async () => {
        try {
          logo.value = await getToolbarLogoImage()
          const INFOITEMS = await getInfoItems()
          const formattedInfoItems = await Promise.all(
            INFOITEMS.map(async item => {
              let infoCategory = await getInfoCategoryById(item.infoCategory.id)
              item.infoCategory = infoCategory

              const titleImage = item.files.entities.find(file =>
                ['titleimage'].includes(file.type)
              )?.id

              if (titleImage !== undefined) {
                let file = await getFile(titleImage)
                item['image'] = await getDataFromBlob(file)
              } else {
                const image = new Image()
                image.src = logo.value
                image.onload = () => {
                  const canvas = document.createElement('canvas')
                  const ctx = canvas.getContext('2d')
                  const imgWidth = 50 // Set desired width of the logo
                  const imgHeight = 50 // Set desired height of the logo
                  canvas.width = imgWidth
                  canvas.height = imgHeight
                  const x = (canvas.width - imgWidth) / 2
                  const y = (canvas.height - imgHeight) / 2
                  ctx.drawImage(image, x, y, imgWidth, imgHeight)
                  fallbackImage.value = canvas.toDataURL()
                }
              }

              const attachedImages = item.files.entities.filter(file =>
                ['image'].includes(file.type)
              )
              const attachedFiles = item.files.entities.filter(file =>
                ['attachment'].includes(file.type)
              )

              if (attachedImages.length > 0) {
                let images = []
                for (const image of attachedImages) {
                  let file = await getFile(image.id)
                  images.push(await getDataFromBlob(file))
                }
                item['attachedImages'] = images
              }

              if (attachedFiles.length > 0) {
                item['filesArray'] = await Promise.all(
                  attachedFiles.map(async item => {
                    const file = await getFile(item?.id)
                    const base64data = await readAsDataURL(file)
                    const fileInfo = await getFileInfo(item?.id)
                    return {
                      source: base64data,
                      fileName: fileInfo.fileName,
                      fileType: fileInfo['mimeType'].split('/')[1],
                    }
                  })
                )
              }

              if (item?.shortText) {
                item['description'] = item.shortText
              }

              if (item?.tags['display-button']) {
                item['displayButton'] = item.tags['display-button']
                item['buttonText'] = item.tags['button-text']
                item['buttonUrl'] = item.tags['button-url']
              }

              item['creationTime'] = moment(item.creationTime).format(
                'MMM DD, YYYY'
              )

              return item
            })
          )

          const sortedInfoItems = lodash.sortBy(formattedInfoItems, [
            item => -moment(item.creationTime, 'MMM DD, YYYY').valueOf(),
          ])

          infoItems.value = sortedInfoItems
          customerName.value = await getToolBarCustomerName()
        } catch (error) {
          console.error('Error fetching info items', error)
        } finally {
          showLoader.value = false
        }
      })

      return {
        hoveredCard,
        infoItems,
        infoItemDetail,
        showLoader,
        customerName,
        logo,
        selectedInfoItem,
        infoItemDetailDialogRef,
        fallbackImage,
      }
    },
  })
</script>

<style scoped>
  .infocenter {
  }

  .toolbar-icon {
    height: 60px;
    align-self: center;
    margin-left: 1rem;
  }

  .info-card {
    border-radius: 12px;
    overflow: hidden;
    background: whitesmoke;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .info-card-hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }

  .v-card-title {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 1.25rem;
    color: #333;
  }

  .v-card-subtitle {
    font-family: 'Roboto', sans-serif;
    color: #50c878;
    margin-bottom: 4px;
  }

  .text-description {
    font-family: 'Roboto', sans-serif;
    color: #666;
    font-size: 0.875rem;
  }

  .read-more-btn {
    font-family: 'Roboto', sans-serif;
    color: #1976d2;
    text-transform: none;
    font-weight: bold;
  }
  .fallback-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .fallback-logo {
    width: 100px;
    height: 100px;
  }

  .read-more-btn v-icon {
    margin-left: 4px;
  }

  @media (max-width: 600px) {
    .info-card {
      margin-bottom: 16px;
    }
    .v-card-title {
      font-size: 1rem;
    }
    .text-description {
      font-size: 0.75rem;
    }
    .v-card-subtitle {
      font-size: 0.75rem;
    }
    .read-more-btn {
      font-size: 0.875rem;
    }
  }
</style>
