<template>
  <div class="homeview">
    <Loader :loading="showLoader" />
    <v-toolbar color="#222222" flat dense class="mb-4">
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title
        style="font-family: 'Material Design Icons'; font-size: 25px !important"
      >
        {{ 'Elevator Control' }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-container fluid class="py-6 chat-container">
      <v-row justify="center" class="mb-4">
        <v-col cols="12" class="text-center">
          <h2>Select your floor and call the desired elevator.</h2>
        </v-col>
      </v-row>
      <v-row justify="center" class="mb-4">
        <v-col cols="12" sm="6" md="4">
          <v-select
            v-model="selectedFloor"
            :items="uniqueFloors"
            label="Select a floor"
            @change="filterElevators"
            variant="solo-inverted"
          ></v-select>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          v-for="elevator in elevators"
          :key="elevator.id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <div
            :class="[
              'custom-card',
              { 'disabled-card': !elevator.hasSelectedFloor },
            ]"
            @click="elevator.hasSelectedFloor && openModal(elevator)"
          >
            <div class="elevator-name">{{ elevator.name }}</div>
            <img
              :src="require('@/assets/General/elevator.png')"
              class="elevator-image"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="modalVisible" max-width="600px">
      <v-card>
        <v-card-title class="headline">
          {{ selectedElevator.name }} Entrances
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col
              v-for="entrance in selectedElevator.entrances"
              :key="entrance.id"
              cols="12"
              sm="6"
              md="4"
            >
              <div
                class="entrance-card"
                :class="{
                  'selected-entrance':
                    stripPrefix(entrance.name) === selectedFloor,
                }"
                @click="
                  stripPrefix(entrance.name) !== selectedFloor &&
                    selectEntrance(entrance)
                "
              >
                <div class="entrance-name">
                  {{ stripPrefix(entrance.name) }}
                </div>
                <v-radio
                  :value="entrance.id"
                  v-model="selectedEntrance"
                  class="entrance-radio"
                />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeModal">Close</v-btn>
          <v-btn color="primary" text @click="confirmSelection">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { ref, defineComponent, onMounted, watch } from 'vue'
  import Loader from '@/components/general/Loader.vue'
  import { getStoredItem } from '@/services/utils'
  import lodash from 'lodash'
  import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
  import { getBuildings } from '@/controllers/BaseController'
  import { getElevators } from '@/controllers/BackboneAPI'

  export default defineComponent({
    name: 'Elevator',
    components: {
      Loader,
    },
    setup() {
      const logo = ref('')
      const showLoader = ref(false)
      const elevators = ref([])
      const uniqueFloors = ref([])
      const selectedFloor = ref(null)
      const modalVisible = ref(false)
      const selectedElevator = ref(null)
      const selectedEntrance = ref(null)

      onMounted(async () => {
        try {
          showLoader.value = true
          logo.value = await getToolbarLogoImage()
          const storedBuilding = await getStoredItem('selectedBuilding')
          let buildings = await getBuildings()
          let selectedBuilding = lodash.find(buildings, {
            name: storedBuilding,
          })
          let elevatorData = await getElevators(selectedBuilding?.id)

          // Collect unique floor entrances
          const floorsSet = new Set()
          elevatorData.forEach(elevator => {
            elevator.entrances.forEach(entrance => {
              const floor = stripPrefix(entrance.name)
              floorsSet.add(floor)
            })
          })
          uniqueFloors.value = Array.from(floorsSet).sort((a, b) => {
            return parseInt(a.match(/\d+/g), 10) - parseInt(b.match(/\d+/g), 10)
          })

          elevators.value = elevatorData.map(elevator => ({
            ...elevator,
            hasSelectedFloor: true,
          }))
          selectedFloor.value = uniqueFloors.value[0]
        } catch (error) {
          console.error('Failed to retrieve user data:', error)
        } finally {
          showLoader.value = false
        }
      })

      const openModal = elevator => {
        selectedElevator.value = {
          ...elevator,
          entrances: lodash.orderBy(
            elevator.entrances,
            entrance => parseInt(entrance.name.match(/\d+/g), 10),
            ['asc']
          ),
        }
        selectedEntrance.value = null
        modalVisible.value = true
      }

      const closeModal = () => {
        modalVisible.value = false
      }

      const confirmSelection = () => {
        // Handle the confirmation of the selected entrance here
        console.log('Selected entrance:', selectedEntrance.value)
        closeModal()
      }

      const stripPrefix = text => {
        return text.split('-').pop().trim()
      }

      const filterElevators = () => {
        elevators.value = elevators.value.map(elevator => ({
          ...elevator,
          hasSelectedFloor: elevator.entrances.some(
            entrance => stripPrefix(entrance.name) === selectedFloor.value
          ),
        }))
      }

      watch(selectedFloor, filterElevators)

      return {
        logo,
        showLoader,
        elevators,
        uniqueFloors,
        selectedFloor,
        modalVisible,
        selectedElevator,
        selectedEntrance,
        openModal,
        closeModal,
        confirmSelection,
        stripPrefix,
      }
    },
  })
</script>

<style scoped>
  .homeview {
    text-align: center;
  }

  .toolbar-icon {
    height: 30px;
    align-self: center;
    margin-left: 1rem;
  }

  .custom-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s, transform 0.3s;
    cursor: pointer;
    padding: 20px;
    margin: 10px;
    border: 1px solid #ddd;
  }

  .custom-card:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
    transform: translateY(-5px);
  }

  .custom-card.disabled-card {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }

  .elevator-name {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
    color: #333;
  }

  .elevator-image {
    width: 100%;
    max-width: 150px;
  }

  .chat-container {
    padding-top: 20px;
  }

  h2 {
    font-family: 'Material Design Icons';
    font-size: 20px;
    color: #555;
    margin-bottom: 20px;
  }

  .entrance-card {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s, transform 0.3s;
    cursor: pointer;
    padding: 20px;
    margin: 10px;
    border: 1px solid #ddd;
    position: relative;
  }

  .entrance-card:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }

  .entrance-card.selected-entrance {
    border: 2px solid green;
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }

  .entrance-name {
    font-weight: bold;
    font-size: 16px;
    color: #333;
    text-align: center;
    margin-bottom: 10px;
  }

  .entrance-radio {
    position: absolute;
    top: 10px;
    right: 10px;
  }
</style>
