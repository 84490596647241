<template>
  <div class="building">
    <Loader :loading="showLoader" />
    <v-toolbar class="custom-toolbar mb-4" flat dense>
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title class="custom-toolbar-title">
        {{ 'Rooms' }}
      </v-toolbar-title>
      <v-btn icon class="custom-toolbar-btn" @click="openMapView">
        <v-icon>mdi-map</v-icon>
      </v-btn>
      <v-btn icon class="custom-toolbar-btn" @click="showSearch = !showSearch">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container>
      <v-row dense>
        <v-col
          v-for="poi in pois"
          :key="poi.id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          class="mb-4"
        >
          <div class="elegant-card">
            <div class="card-image-container">
              <img v-if="poi.image" :src="poi.image" class="card-image" />
              <div v-else class="fallback-image">
                <img :src="fallbackImage" class="fallback-logo" />
              </div>
            </div>
            <div class="card-content">
              <div class="card-header">{{ poi.name }}</div>
              <div class="card-body">
                <div>
                  Capacity:
                  <strong>{{ poi?.capacity }}</strong>
                </div>
                <div>
                  Category:
                  <strong>{{ poi?.category?.name }}</strong>
                </div>
                <div>
                  Availability:
                  <strong>{{ poi?.availableFrom }}</strong>
                </div>
                <div>
                  Floor:
                  <strong>{{ poi?.floor?.name }}</strong>
                </div>
              </div>
              <div class="card-footer">
                <button
                  v-for="hardware in poi.hardwares"
                  :key="hardware.id"
                  class="elegant-button"
                >
                  <i
                    v-if="hardware.type === 'LIGHT'"
                    class="mdi mdi-lightbulb-on-outline"
                  ></i>
                  <i
                    v-if="hardware.type === 'BLINDS'"
                    class="mdi mdi-blinds"
                  ></i>
                  <i
                    v-if="hardware.type === 'VENTILATOR'"
                    class="mdi mdi-fan"
                  ></i>
                  <i
                    v-if="hardware.type === 'HEATER'"
                    class="mdi mdi-thermometer"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <GoogleMapsView ref="mapView" />
  </div>
</template>

<script>
  import { defineComponent, ref, onMounted } from 'vue'
  import {
    getToolbarLogoImage,
    getToolBarCustomerName,
  } from '@/assets/Branding/branding.js'
  import { useRouter, useRoute } from 'vue-router'
  import {
    getPoiCategoryById,
    getBuildings,
    getPoiById,
    getFloorById,
    getFile,
    getDataFromBlob,
  } from '@/controllers/BaseController.js'
  import GoogleMapsView from '@/components/general/GoogleMapsView.vue'
  import { getHardwareByType } from '@/controllers/BackboneAPI'
  import moment from 'moment-timezone'

  import Loader from '@/components/general/Loader.vue'
  import lodash from 'lodash'

  export default defineComponent({
    name: 'PoisListView',
    components: {
      Loader,
      GoogleMapsView,
    },
    setup() {
      const showLoader = ref(false)
      const showSearch = ref(false)
      const logo = ref('')
      const customerName = ref('')
      const router = useRouter()
      const route = useRoute()
      const pois = ref([])
      const selectedCategory = ref(null)
      const selectedBuilding = ref(null)
      const fallbackImage = ref('')
      const mapView = ref(null)

      function openMapView() {
        let SELECTED_BUILDING = selectedBuilding.value
        SELECTED_BUILDING = JSON.parse(JSON.stringify(SELECTED_BUILDING))
        let POI_CATEGORIES = [
          JSON.parse(JSON.stringify(selectedCategory.value)),
        ]
        let POIS = pois.value
        POIS = lodash.flatten(POIS)
        mapView.value.openMap(SELECTED_BUILDING, POIS, POI_CATEGORIES)
      }

      onMounted(async () => {
        try {
          showLoader.value = true

          // Concurrently fetch logo and customer name
          const [logoImage, customerNameValue] = await Promise.all([
            getToolbarLogoImage(),
            getToolBarCustomerName(),
          ])

          logo.value = logoImage
          customerName.value = customerNameValue

          // Create fallback image
          const canvas = document.createElement('canvas')
          canvas.width = 200 // Set the desired width
          canvas.height = 200 // Set the desired height
          const ctx = canvas.getContext('2d')

          // Draw black background
          ctx.fillStyle = 'black'
          ctx.fillRect(0, 0, canvas.width, canvas.height)

          // Draw customer logo
          const image = new Image()
          image.src = logoImage
          image.onload = () => {
            const imgWidth = 50 // Set desired width of the logo
            const imgHeight = 50 // Set desired height of the logo
            const x = (canvas.width - imgWidth) / 2
            const y = (canvas.height - imgHeight) / 2
            ctx.drawImage(image, x, y, imgWidth, imgHeight)
            fallbackImage.value = canvas.toDataURL()
          }

          const categoryId = route.params.category_id
          const buildingId = route.params.building_id

          const hardwareTypes = ['BLINDS', 'VENTILATOR', 'LIGHT', 'HEATER']
          const hardwarePromises = hardwareTypes.map(type =>
            getHardwareByType(buildingId, type).catch(() => [])
          )
          const hardwareArrays = await Promise.all(hardwarePromises)
          const hardwareData = Object.fromEntries(
            hardwareTypes.map((type, index) => [type, hardwareArrays[index]])
          )
          console.log(hardwareData)

          // Fetch building and category data concurrently
          const [buildings, category] = await Promise.all([
            getBuildings(),
            getPoiCategoryById(categoryId),
          ])

          // Find selected building
          selectedBuilding.value = buildings.find(b => b.id === buildingId)
          selectedCategory.value = category

          // Fetch POI and floor data concurrently
          const poiIds = category?.pois?.ids || []
          const selectedPoisPromises = poiIds.map(async poiId => {
            const poi = await getPoiById(poiId)
            const floor = await getFloorById(poi?.floors?.ids[0])

            let image = null
            let logo = null

            // Fetch image if exists
            if (lodash.some(poi.files.entities, { type: 'image' })) {
              const imageId = poi.files.entities.find(
                file => file.type === 'image'
              ).id
              const blob = await getFile(imageId)
              image = await getDataFromBlob(blob)
            }

            // Fetch logo if exists
            if (lodash.some(poi.files.entities, { type: 'logo' })) {
              const logoId = poi.files.entities.find(
                file => file.type === 'logo'
              ).id
              const blob = await getFile(logoId)
              logo = await getDataFromBlob(blob)
            }

            if (poi.availableFrom && poi.availableUntil) {
              poi.availableFrom = moment
                .tz(poi.availableFrom, 'HH:mm', 'Europe/Paris')
                .utc()
                .format('HH:mm')
              poi.availableUntil = moment
                .tz(poi.availableUntil, 'HH:mm', 'Europe/Paris')
                .utc()
                .format('HH:mm')
            }

            if (
              poi?.configs['room-control'] !== undefined &&
              poi?.configs['room-control'] === 'true'
            ) {
              const hardwares = hardwareTypes.flatMap(type =>
                hardwareData[type]
                  .filter(hardware => hardware.configs['poi-id'] === poi.id)
                  .map(hardware => ({ type, id: hardware.id }))
              )
              poi.hardwares = hardwares
            }

            return { ...poi, floor, image, logo, category }
          })

          let selectedPois = await Promise.all(selectedPoisPromises)
          selectedPois = lodash.orderBy(
            selectedPois,
            [
              poi => {
                const name = lodash.get(poi, 'name', '')
                const isNumeric = /^\d/.test(name)
                return (isNumeric ? '1' : '2') + name
              },
            ],
            ['asc']
          )
          pois.value = selectedPois
        } catch (error) {
          console.error(error)
        } finally {
          showLoader.value = false
        }
      })

      return {
        showLoader,
        showSearch,
        openMapView,
        customerName,
        router,
        route,
        logo,
        pois,
        selectedCategory,
        selectedBuilding,
        fallbackImage,
        mapView,
        moment,
      }
    },
  })
</script>

<style scoped>
  .toolbar-icon {
    height: 30px;
    align-self: center;
    margin-left: 1rem;
  }

  .custom-toolbar {
    background: linear-gradient(135deg, #222222, #444444);
    color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    padding: 16px;
  }

  .custom-toolbar-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 120px);
  }

  .custom-toolbar-btn {
    color: #ffffff;
    margin-right: 8px;
  }

  @media (max-width: 600px) {
    .custom-toolbar {
      padding: 8px;
    }

    .custom-toolbar-title {
      font-size: 1.2rem;
      max-width: calc(100% - 88px);
    }

    .custom-toolbar-btn {
      margin-right: 4px;
    }
  }

  .mb-4 {
    margin-bottom: 1rem;
  }

  .elegant-card {
    cursor: pointer;
    overflow: hidden;
    border-radius: 12px;
    position: relative;
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .elegant-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.3);
  }

  .card-image-container {
    width: 100%;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    position: relative;
  }

  .card-image,
  .fallback-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .fallback-image {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000000;
  }

  .fallback-logo {
    width: 100px;
    height: 100px;
  }

  .card-content {
    padding: 16px;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .card-header {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .card-body {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    margin-bottom: 16px;
    flex: 1;
  }

  .card-footer {
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .elegant-button {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    border: none;
    margin-right: 8px;
    color: white;
    transition: background-color 0.2s ease;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
  }

  .elegant-button:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
</style>
