<template>
  <v-app>
    <v-main
      :style="{
        paddingBottom: showBottomNav ? `${bottomNavHeight}px` : '0px',
      }"
    >
      <BottomNavigation
        ref="bottomNavRef"
        v-if="showBottomNav"
        :options="options"
        :selected="selected"
        @update:selected="selected = $event"
      />
      <router-view v-slot="{ Component }">
        <v-slide-x-transition mode="out-in">
          <component :is="Component" />
        </v-slide-x-transition>
      </router-view>
    </v-main>
  </v-app>
</template>

<script>
  import { computed, onMounted, ref, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import BottomNavigation from '@/components/general/BottomNavigation'
  import { getStoredItem, setStoredItem } from '@/services/utils'
  import { getCustomer } from '@/assets/Branding/branding.js'

  export default {
    components: {
      BottomNavigation,
    },
    setup() {
      const route = useRoute()
      const router = useRouter()
      const selected = ref(1)
      const bottomNavRef = ref(null)
      const bottomNavHeight = ref(0)

      const options = [
        {
          id: 1,
          icon: 'mdi-newspaper-variant-outline',
          title: 'News',
          path: { name: 'InfoCenterView' },
        },
        {
          id: 2,
          icon: 'mdi-bullhorn-outline',
          title: 'Info',
          path: { name: 'MarketingView' },
        },
        {
          id: 3,
          icon: 'mdi-office-building-marker-outline',
          title: 'Office',
          path: { name: 'BuildingView' },
        },
        {
          id: 4,
          icon: 'mdi-calendar-month-outline',
          title: 'Booking',
          path: { name: 'BookingPoisList' },
        },
        {
          id: 5,
          icon: 'mdi-cog-outline',
          title: 'Hardware',
          childs: [
            {
              id: 51,
              icon: 'mdi-cellphone-key',
              title: 'Locks',
              path: { name: 'LocksView' },
            },
            {
              id: 52,
              icon: 'mdi-remote',
              title: 'Control',
              path: { name: 'RoomControlPois' },
            },
            {
              id: 52,
              icon: 'mdi-elevator',
              title: 'Elevator',
              path: { name: 'Elevator' },
            },
          ],
        },
        {
          id: 6,
          icon: 'mdi-chat-outline',
          title: 'Chat',
          path: { name: 'ChatUsersList' },
        },
        {
          id: 7,
          icon: 'mdi-menu-open',
          title: 'Menu',
          path: { name: 'MenuView' },
        },
      ]

      const showBottomNav = computed(
        () =>
          route.name !== 'Login' && !window.location.href.includes('/oauth2')
      )

      onMounted(async () => {
        try {
          let storedBuilding = await getStoredItem('selectedBuilding')

          if (!storedBuilding) {
            let customerName = await getCustomer()
            const { getBuildings } = await import(
              '@/controllers/BaseController'
            )
            const apiBuildings = await getBuildings()
            if (customerName === 'pia') {
              let building = apiBuildings.find(
                building => building.name === 'Teichhaus'
              )
              if (building) {
                storedBuilding = building.name
              }
            } else {
              storedBuilding = apiBuildings[0]?.name || null
            }
            await setStoredItem('selectedBuilding', storedBuilding)
          }
        } catch (error) {
          console.log(error)
        }

        if (bottomNavRef.value) {
          bottomNavHeight.value = bottomNavRef.value.$el.clientHeight
        }
      })

      watch(
        () => bottomNavRef.value,
        (newVal, oldVal) => {
          if (newVal) {
            bottomNavHeight.value = newVal.$el.clientHeight
          }
        },
        { immediate: true }
      )

      return {
        options,
        selected,
        showBottomNav,
        bottomNavRef,
        bottomNavHeight,
      }
    },
  }
</script>

<style>
  .unchecked .btn-title {
    visibility: visible !important;
    margin-top: 2.4rem;
  }
</style>
