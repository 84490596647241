<template>
  <v-container class="auth-callback" fill-height fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" class="text-center">
        <v-card class="mx-auto" color="dark-grey" dark>
          <v-card-text>
            <v-progress-circular
              v-if="processing"
              indeterminate
              color="white"
            ></v-progress-circular>
            <v-fade-transition>
              <div v-if="!processing">
                <v-icon large color="success">mdi-check-circle</v-icon>
                <div>Login successful</div>
                <div class="caption">
                  You will be redirected shortly. Please wait...
                </div>
              </div>
            </v-fade-transition>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { ref, onMounted } from 'vue'
  import { useOAuthService } from '@/services/OAuthService'
  import { getStoredItem, setStoredItem } from '@/services/utils'
  import { useRouter } from 'vue-router'
  import { loadConfig } from '@/configLoader'
  import { getCustomer } from '@/assets/Branding/branding.js'

  export default {
    name: 'AuthCallback',
    setup() {
      const processing = ref(true)
      const router = useRouter()
      const { setToken, isLoggedIn } = useOAuthService()

      onMounted(async () => {
        console.log(
          '[AuthCallback] - Component mounted, starting authentication process.'
        )
        const url = window.location.href
        const hash = url.includes('#')
          ? url.substring(url.indexOf('#') + 1)
          : ''
        const params = new URLSearchParams(hash)
        const accessToken = params.get('access_token')

        if (accessToken) {
          console.log('[AuthCallback] - Access token found, setting token.')
          await setToken(accessToken)
          let storedBuilding = await getStoredItem('selectedBuilding')
          if (!storedBuilding || storedBuilding === ' Villa') {
            const { getBuildings } = await import(
              '@/controllers/BaseController'
            )
            const customer = await getCustomer()
            const apiBuildings = await getBuildings()
            if (customer === 'pia') {
              let building = apiBuildings.find(
                building => building.name === 'Teichhaus'
              )
              if (building) {
                storedBuilding = building.name
              }
            }
            storedBuilding = apiBuildings[0]?.name || null
            await setStoredItem('selectedBuilding', storedBuilding)
            console.log(`[AuthCallback] - Building set to: ${storedBuilding}`)
          }

          if (await isLoggedIn()) {
            console.log(
              '[AuthCallback] - Logged in successfully, redirecting to home view.'
            )
            processing.value = false
            // Using Vue Router to redirect to home view
            router.push('/')
          }
        } else {
          console.error(
            '[AuthCallback] - No access token found. Redirecting to login page.'
          )
          // Using Vue Router to redirect to login page
          router.push('/login')
        }
      })

      return { processing }
    },
  }
</script>

<style scoped>
  .auth-callback {
    background-color: black;
    color: white;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
