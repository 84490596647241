<template>
  <div class="menu-container">
    <v-toolbar color="#222222" prominent elevation="5">
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title>Menu</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-export</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container>
      <v-card class="menu-card">
        <v-list>
          <v-list-item
            :prepend-avatar="user_logo"
            :subtitle="userProfile ? userProfile.email : ''"
            :title="userProfile?.displayName ? userProfile.displayName : ''"
          ></v-list-item>
          <v-divider></v-divider>
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            :value="item"
            class="menu-item"
            @click="goToMenuItem(item.text)"
          >
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>

            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import { defineComponent, onMounted, ref } from 'vue'
  import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
  import { useRouter } from 'vue-router'
  import user_logo from '@/assets/logos/user_icon.png'
  import { doLogout } from '@/controllers/BackboneAPI'
  import { useOAuthService } from '@/services/OAuthService'

  export default defineComponent({
    name: 'MenuView',
    setup() {
      const router = useRouter()
      const OAuthService = useOAuthService()
      const userProfile = ref(null)
      const goToMenuItem = async item => {
        switch (item) {
          case 'Profile':
            console.log('Profile clicked')
            break
          case 'Room Control':
            router.push({ name: 'RoomControlPois' }).catch(error => {
              console.log(error)
            })
            break
          case 'Analytics':
            router.push({ name: 'AnalyticsList' }).catch(error => {
              console.log(error)
            })
            break
          case 'Language':
            console.log('Language clicked')
            break
          case 'Admin Panel':
            console.log('Admin Panel clicked')
            break
          case 'Booking Management':
            console.log('Booking Management clicked')
            break
          case 'Vouchers':
            router.push({ name: 'Vouchers' }).catch(error => {
              console.log(error)
            })
            break
          case 'Privacy':
            console.log('Privacy clicked')
            break
          case 'Help':
            console.log('Help clicked')
            break
          case 'Logout':
            await doLogout()
            break
          default:
            console.log('Unknown item clicked')
            break
        }
      }

      const items = ref([
        { text: 'Profile', icon: 'mdi-account' },
        { text: 'Room Control', icon: 'mdi-lightbulb-on' },
        { text: 'Analytics', icon: 'mdi-chart-bar' },
        { text: 'Language', icon: 'mdi-translate' },
        { text: 'Admin Panel', icon: 'mdi-shield-account' },
        { text: 'Booking Management', icon: 'mdi-calendar-text' },
        { text: 'Vouchers', icon: 'mdi-ticket-percent' },
        { text: 'Privacy', icon: 'mdi-lock' },
        { text: 'Help', icon: 'mdi-help-circle' },
        { text: 'Logout', icon: 'mdi-logout' },
      ])
      const logo = ref('')

      onMounted(async () => {
        logo.value = await getToolbarLogoImage()
        let profile = await OAuthService.getUserProfile()
        userProfile.value = profile
      })

      return {
        logo,
        items,
        goToMenuItem,
        router,
        user_logo,
        userProfile,
      }
    },
  })
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

  .menu-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: 'Roboto', sans-serif;
  }

  .v-toolbar-title {
    font-weight: 500;
    font-size: 1.5rem;
  }

  .v-toolbar {
    background-color: #333;
    color: white;
  }

  .menu-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; /* Align items to the left */
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 20px;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
  }

  .menu-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-10px);
  }

  .menu-item {
    transition: all 0.3s ease;
    padding: 15px;
    margin: 10px 0;
    border-radius: 10px;
    width: 100%; /* Ensure items take full width */
    display: flex;
    justify-content: flex-start; /* Align content to the left */
  }

  .menu-item:hover {
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
  }

  .v-list-item-title {
    font-weight: 400;
  }

  .v-list-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center; /* Vertically align items */
  }

  .toolbar-icon {
    height: 30px;
    align-self: center;
    margin-left: 1rem;
  }
</style>
