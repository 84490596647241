<template>
  <div class="create-manage-booking">
    <v-toolbar color="black" dark>
      <v-icon style="margin-left: 10px" size="40" @click="goBack">
        mdi-arrow-left-bold-circle
      </v-icon>
      <v-toolbar-title class="custom-toolbar-title" style="margin-left: 0.5rem">
        {{ isNew ? 'Create Booking' : 'Edit Booking' }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        :class="[
          'submit-button',
          canSubmit ? 'submit-button-green' : 'submit-button-red',
        ]"
        style="margin-right: 20px"
        small
        dark
        @click="submitForm"
      >
        Submit
      </v-btn>
    </v-toolbar>
    <Loader :loading="showLoader" />
    <v-container class="form-container" fluid>
      <v-row justify="center">
        <v-col cols="12" md="8">
          <div class="form-card">
            <form ref="form" @submit.prevent>
              <v-row>
                <v-col cols="12" sm="6">
                  <div class="form-group">
                    <label for="location">
                      <v-icon small class="label-icon">mdi-map-marker</v-icon>
                      Location
                    </label>
                    <input
                      type="text"
                      id="location"
                      v-model="reservation.poi.name"
                      disabled
                      class="highlighted-text"
                    />
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="form-group">
                    <label for="category">
                      <v-icon small class="label-icon">
                        mdi-office-building
                      </v-icon>
                      Category
                    </label>
                    <input
                      type="text"
                      id="category"
                      v-model="reservation.category.name"
                      disabled
                      class="highlighted-text"
                    />
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="form-group">
                    <label for="floor">
                      <v-icon small class="label-icon">mdi-elevator</v-icon>
                      Floor
                    </label>
                    <input
                      type="text"
                      id="floor"
                      v-model="reservation.floor.name"
                      disabled
                      class="highlighted-text"
                    />
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="form-group">
                    <label for="dateRange">
                      <v-icon small class="label-icon">mdi-calendar</v-icon>
                      Date and Time
                    </label>
                    <div class="date-range" @click="datePickerDialog = true">
                      <input type="text" v-model="formattedRange" readonly />
                    </div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="form-group">
                    <label for="subject">
                      <v-icon small class="label-icon">
                        mdi-grease-pencil
                      </v-icon>
                      Subject
                    </label>
                    <input
                      type="text"
                      id="subject"
                      v-model="reservation.title"
                      maxlength="50"
                    />
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="form-group">
                    <label for="creatorName">
                      <v-icon small class="label-icon">mdi-account</v-icon>
                      Creator Name
                    </label>
                    <input
                      type="text"
                      id="creatorName"
                      v-model="reservation.creatorName"
                      disabled
                      class="highlighted-text"
                    />
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="form-group">
                    <label for="creatorEmail">
                      <v-icon small class="label-icon">mdi-email</v-icon>
                      Creator Email
                    </label>
                    <input
                      type="email"
                      id="creatorEmail"
                      v-model="reservation.creatorEmail"
                      disabled
                      class="highlighted-text"
                    />
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="form-group">
                    <label for="description">
                      <v-icon small class="label-icon">mdi-text</v-icon>
                      Description
                    </label>
                    <v-textarea
                      id="description"
                      v-model="reservation.description"
                      maxlength="80"
                    ></v-textarea>
                  </div>
                </v-col>
              </v-row>
            </form>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div v-if="datePickerDialog" class="date-picker-dialog">
      <div class="date-picker-container">
        <DatePicker
          is-range
          :min-date="new Date()"
          :max-date="moment().add(59, 'days').toDate()"
          v-model.range="tempRange"
          mode="dateTime"
          :is-dark="true"
        ></DatePicker>
        <div class="date-picker-actions">
          <button @click="discardRange">Cancel</button>
          <button @click="confirmRange">Confirm</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, computed } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import Loader from '@/components/general/Loader.vue'
  import { DatePicker } from 'v-calendar'
  import moment from 'moment'

  export default {
    name: 'CreateEditBooking',
    components: {
      Loader,
      DatePicker,
    },
    setup() {
      const router = useRouter()
      const route = useRoute()

      const reservation = ref({
        id: '',
        title: 'Meeting Room Reservation',
        creatorName: 'Khizar Alam',
        poi: { id: '', name: '3.05 Meeting Room' },
        category: { id: '', name: 'Meeting Room' },
        floor: { id: '', name: 'Floor 1' },
        creatorEmail: 'khizar.alam@pinestack.io',
        personCount: 1,
        startTime: new Date(),
        endTime: new Date(),
        description: 'This is testing',
        range: { start: new Date(), end: moment().add(1, 'hour').toDate() },
      })

      const showLoader = ref(false)
      const datePickerDialog = ref(false)
      const tempRange = ref({ ...reservation.value.range })

      const isNew = computed(() => !route.params.id)
      const isEdit = computed(() => !!route.params.id)

      const formattedRange = computed(() => {
        const { start, end } = reservation.value.range
        return `${moment(start).format('DD-MM-YY HH:mm')} - ${moment(
          end
        ).format('DD-MM-YY HH:mm')}`
      })

      const canSubmit = computed(() => {
        return (
          reservation.value.title &&
          reservation.value.description &&
          reservation.value.range.start &&
          reservation.value.range.end
        )
      })

      const submitForm = () => {
        if (canSubmit.value) {
          // Submit form logic
        } else {
          // Show validation errors
        }
      }

      const goBack = () => {
        router.go(-1)
      }

      const discardRange = () => {
        datePickerDialog.value = false
      }

      const confirmRange = () => {
        reservation.value.range = { ...tempRange.value }
        datePickerDialog.value = false
      }

      return {
        reservation,
        showLoader,
        goBack,
        formattedRange,
        datePickerDialog,
        tempRange,
        discardRange,
        confirmRange,
        moment,
        isNew,
        isEdit,
        canSubmit,
        submitForm,
      }
    },
  }
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

  body {
    font-family: 'Inter', sans-serif;
  }

  .v-toolbar-title {
    font-family: 'Inter', sans-serif;
  }

  .form-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    height: calc(100vh - 56px); /* Adjust for toolbar height */
    box-sizing: border-box;
    overflow-y: auto;
  }

  .form-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 20px;
  }

  .form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .back-icon {
    font-size: 24px;
    cursor: pointer;
  }

  .submit-button {
    background: #28a745;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }

  .submit-button:hover {
    background: #218838;
  }

  .submit-button-green {
    background: #28a745;
  }

  .submit-button-green:hover {
    background: #218838;
  }

  .submit-button-red {
    background: #ff5252;
  }

  .submit-button-red:hover {
    background: #e04747;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-group label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-weight: bold;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #333;
  }

  .label-icon {
    margin-right: 8px;
  }

  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.3s ease;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
  }

  .form-group input:focus,
  .form-group textarea:focus {
    border-color: #80bdff;
    outline: none;
  }

  .form-group input[disabled],
  .form-group textarea[disabled] {
    font-weight: bold;
    color: #333;
    background-color: #f9f9f9;
  }

  .date-range {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
  }

  .date-range input {
    border: none;
    outline: none;
    background: transparent;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
  }

  .date-picker-dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .date-picker-container {
    background: #0f172a;
    padding: 20px;
    border-radius: 10px;
  }

  .date-picker-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .date-picker-actions button {
    padding: 4px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }

  .date-picker-actions button:first-child {
    background: #ff5252;
    color: #fff;
  }

  .date-picker-actions button:first-child:hover {
    background: #e04747;
  }

  .date-picker-actions button:last-child {
    background: darkcyan;
    color: #fff;
  }

  .date-picker-actions button:last-child:hover {
    background: #006f6f;
  }

  @media (max-width: 600px) {
    .form-container {
      padding: 10px;
    }
    .form-card {
      padding: 10px;
    }
    .submit-button {
      margin-right: 10px;
    }
    .v-toolbar-title {
      font-size: 18px;
    }
  }
</style>
