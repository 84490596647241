<template>
  <div class="building">
    <Loader :loading="showLoader" />
    <v-toolbar class="custom-toolbar mb-4" flat dense>
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title class="custom-toolbar-title">
        {{ 'Work Space' }}
      </v-toolbar-title>
      <v-btn icon class="custom-toolbar-btn" @click="openMapView">
        <v-icon>mdi-map</v-icon>
      </v-btn>
      <v-btn icon class="custom-toolbar-btn" @click="openDialog">
        <v-icon>mdi-home-city-outline</v-icon>
      </v-btn>
      <v-btn icon class="custom-toolbar-btn" @click="showSearch = !showSearch">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-fade-transition>
        <v-text-field
          v-if="showSearch"
          v-model="search"
          label="Search"
          single-line
          hide-details
          class="search-field"
          dense
        ></v-text-field>
      </v-fade-transition>
    </v-toolbar>

    <v-dialog v-model="showDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>Select Building</v-card-title>
        <v-card-text>
          <v-select
            :items="buildings"
            item-value="name"
            item-title="name"
            label="Choose a building"
            v-model="tempSelectedBuilding"
            return-object
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="confirmSelection">
            Confirm
          </v-btn>
          <v-btn color="red darken-1" text @click="cancelSelection">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container>
      <div
        class="subtitle-2"
        style="margin-top: -1.5rem"
        v-if="selectedBuilding"
      >
        <v-chip
          prepend-icon="mdi-office-building"
          class="custom-chip ma-2"
          color="dark"
          label
        >
          {{ selectedBuilding }}
        </v-chip>
      </div>
      <v-row dense>
        <v-col
          v-for="poiCategory in poiCategories"
          :key="poiCategory.id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          class="mb-4"
        >
          <v-card @click="goToPoiCategory(poiCategory)" class="elegant-card">
            <div class="card-image-container">
              <img :src="poiCategory.image" class="card-image" />
              <div class="text-overlay">
                <div class="text-h6">{{ poiCategory.name }}</div>
                <div class="subtitle-1">
                  Available Rooms:
                  <strong>{{ poiCategory.pois?.length }}</strong>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <GoogleMapsView ref="mapView" />
  </div>
</template>

<script>
  import { defineComponent, ref, onMounted, computed, watch } from 'vue'
  import { debounce } from 'lodash'
  import { Preferences } from '@capacitor/preferences'
  import { Device } from '@capacitor/device'
  import { getStoredItem, setStoredItem } from '@/services/utils.js'
  import {
    getToolbarLogoImage,
    getToolBarCustomerName,
  } from '@/assets/Branding/branding.js'
  import lodash from 'lodash'
  import {
    getBuildings,
    getPoiCategoriesByBuildingId,
    getFile,
    getDataFromBlob,
  } from '@/controllers/BaseController'
  import Loader from '@/components/general/Loader.vue'
  // import MapView from "@/components/MapView.vue";
  import GoogleMapsView from '@/components/general/GoogleMapsView.vue'
  import { useRouter } from 'vue-router'

  export default defineComponent({
    name: 'BuildingView',
    components: {
      Loader,
      // MapView,
      GoogleMapsView,
    },
    setup() {
      const showDialog = ref(false)
      const showSearch = ref(false)
      const search = ref('')
      const logo = ref('')
      const customerName = ref('')
      const showLoader = ref(true)
      const buildings = ref([])
      const selectedBuilding = ref(null)
      const tempSelectedBuilding = ref(null)
      const poiCategories = ref([])
      const mapView = ref(null)
      const router = useRouter()

      onMounted(async () => {
        try {
          showLoader.value = true
          const [toolbarLogo, customer, apiBuildings] = await Promise.all([
            getToolbarLogoImage(),
            getToolBarCustomerName(),
            getBuildings(),
          ])

          logo.value = toolbarLogo
          customerName.value = customer
          buildings.value = apiBuildings

          const storedBuilding = await getStoredItem('selectedBuilding')
          selectedBuilding.value =
            storedBuilding || apiBuildings[0]?.name || null
          if (!storedBuilding) {
            await setStoredItem('selectedBuilding', selectedBuilding.value)
          }
          let selectedBuildingId = apiBuildings.find(
            building => building.name === selectedBuilding.value
          )?.id
          let categories = await getPoiCategoriesByBuildingId(
            selectedBuildingId
          )

          await Promise.all(
            categories.map(async category => {
              let image = lodash.some(category.files.entities, {
                type: 'image',
              })
              if (image) {
                const imageId = category.files.entities.find(
                  file => file.type === 'image'
                ).id
                const blob = await getFile(imageId)
                const imageDataUrl = await getDataFromBlob(blob)
                category.image = imageDataUrl
              } else {
                category.image = require('@/assets/CategoryImages/teamzone_iq.jpg')
              }
            })
          )

          poiCategories.value = categories
          showLoader.value = false
        } catch (error) {
          console.error('Error fetching buildings:', error)
        }
      })

      const filteredBuildings = computed(() => {
        return buildings.value.filter(building =>
          building.name.toLowerCase().includes(search.value.toLowerCase())
        )
      })

      watch(
        search,
        debounce(() => {
          console.log('Search debounced:', search.value)
        }, 300)
      )

      function toggleSearch() {
        showSearch.value = !showSearch.value
      }

      function confirmSelection() {
        let building = JSON.parse(JSON.stringify(tempSelectedBuilding.value))
        building = building.name
        selectedBuilding.value = building
        setStoredItem('selectedBuilding', building)
        showDialog.value = false
        window.location.reload()
      }

      function cancelSelection() {
        tempSelectedBuilding.value = selectedBuilding.value
        showDialog.value = false
      }

      function goToPoiCategory(poiCategory) {
        let category = JSON.parse(JSON.stringify(poiCategory))
        let buildingSelected = buildings.value.find(
          building => building.name === selectedBuilding.value
        )
        buildingSelected = JSON.parse(JSON.stringify(buildingSelected))
        router.push({
          name: 'PoisListView',
          params: {
            building_id: buildingSelected.id,
            category_id: category.id,
          },
        })
      }

      function openDialog() {
        tempSelectedBuilding.value = selectedBuilding.value
        showDialog.value = true
      }

      function openMapView() {
        let SELECTED_BUILDING = buildings.value.find(
          building => building.name === selectedBuilding.value
        )
        SELECTED_BUILDING = JSON.parse(JSON.stringify(SELECTED_BUILDING))
        let POI_CATEGORIES = JSON.parse(JSON.stringify(poiCategories.value))
        let POIS = []
        POI_CATEGORIES.forEach(category => {
          POIS.push(category.pois)
        })
        POIS = lodash.flatten(POIS)
        mapView.value.openMap(SELECTED_BUILDING, POIS, POI_CATEGORIES)
      }

      return {
        showDialog,
        showSearch,
        search,
        logo,
        customerName,
        buildings: filteredBuildings,
        selectedBuilding,
        tempSelectedBuilding,
        showLoader,
        poiCategories,
        toggleSearch,
        confirmSelection,
        goToPoiCategory,
        cancelSelection,
        openDialog,
        openMapView,
        mapView,
      }
    },
  })
</script>

<style scoped>
  .building {
  }
  .custom-chip {
    font-size: 0.9rem; /* Larger font size */
    padding: 16px;
    border-radius: 8px;
    background-color: black; /* Custom dark background color */
    color: #ffffff; /* Custom text color */
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  .custom-chip:hover {
    background-color: black; /* Slightly lighter color on hover */
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
    transform: translateY(-3px); /* Subtle lift on hover */
  }

  .toolbar-icon {
    height: 30px;
    align-self: center;
    margin-left: 1rem;
  }

  .mb-4 {
    margin-bottom: 1rem;
  }

  .elegant-card {
    cursor: pointer;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    line-height: 1.5;
    height: 220px;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    will-change: transform, box-shadow;
  }

  .elegant-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.5);
  }

  .card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
    will-change: transform, opacity;
    opacity: 0.7; /* Lower opacity for the image */
  }

  .elegant-card:hover .card-image {
    opacity: 0.85; /* Slightly increase opacity on hover */
    transform: scale(1.03); /* Subtle zoom effect on hover */
  }

  .text-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(
      0,
      0,
      0,
      0.5
    ); /* Semi-transparent overlay for text visibility */
    color: white;
    padding: 16px;
    transition: background-color 0.5s ease-out;
    will-change: background-color;
    z-index: 1; /* Ensure the overlay is above the image */
  }

  .elegant-card:hover .text-overlay {
    background: rgba(0, 0, 0, 0.7); /* Increase the overlay opacity on hover */
  }

  .white--text {
    color: white;
  }

  .custom-toolbar {
    background: linear-gradient(135deg, #222222, #444444);
    color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    padding: 16px;
  }

  .custom-toolbar-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 120px); /* Adjust based on available space */
  }

  .custom-toolbar-btn {
    color: #ffffff;
    margin-right: 8px;
  }

  .toolbar-icon {
    height: 30px;
    align-self: center;
    margin-left: 1rem;
  }

  @media (max-width: 600px) {
    .custom-toolbar {
      padding: 8px;
    }

    .custom-toolbar-title {
      font-size: 1.2rem;
      max-width: calc(
        100% - 88px
      ); /* Adjust max-width to fit smaller screens */
    }

    .custom-toolbar-btn {
      margin-right: 4px;
    }
  }
</style>
