<template>
  <CurvedBottomNavigation
    v-if="showBottomNav"
    :options="options"
    v-model="localSelected"
    foreground-color="#43A7F9"
    badge-color="#FBC02D"
    background-color="#FFFFFF"
    icon-color="#000000"
    @update:modelValue="updateSelected"
  >
    <template #icon="{ props }">
      <v-icon>{{ props.icon }}</v-icon>
    </template>
    <template #title="{ props }">
      <b>{{ props.title }}</b>
    </template>

    <!-- child -->
    <template #child-icon="{ props }">
      <v-icon>{{ props.icon }}</v-icon>
    </template>
    <template #child-title="{ props }">
      <b>{{ props.title }}</b>
    </template>
  </CurvedBottomNavigation>
</template>

<script>
  import { ref, computed, onMounted } from 'vue'
  import { useRoute } from 'vue-router'
  import { CurvedBottomNavigation } from 'bottom-navigation-vue'
  import 'bottom-navigation-vue/dist/style.css'
  import { VIcon } from 'vuetify/lib/components/VIcon'
  import { getBottomBarColor } from '@/assets/Branding/branding.js'

  export default {
    components: {
      CurvedBottomNavigation,
      VIcon,
    },
    props: {
      options: Array,
      selected: Number,
    },
    setup(props, { emit }) {
      const route = useRoute()
      const showBottomNav = computed(
        () =>
          route.name !== 'Login' && !window.location.href.includes('/oauth2')
      )
      const bottomBarColor = ref('')
      const bottomNavRef = ref(null)
      const localSelected = ref(props.selected)

      const updateSelected = value => {
        emit('update:selected', value)
      }

      onMounted(async () => {
        bottomBarColor.value = await getBottomBarColor()
        if (bottomNavRef.value) {
          document.documentElement.style.setProperty(
            '--bottom-nav-height',
            bottomNavRef.value.clientHeight + 'px'
          )
        }
      })

      return {
        showBottomNav,
        bottomBarColor,
        bottomNavRef,
        localSelected,
        updateSelected,
      }
    },
  }
</script>

<style scoped>
  /* Your styles here */
</style>
