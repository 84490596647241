import { useOAuthService } from '@/services/OAuthService'
import axios from 'axios'
import { loadConfig } from '@/configLoader'
import lodash from 'lodash'
import * as Flatted from 'flatted'
import { storeBlob, retrieveBlob } from '@/services/utils'
import { Device } from '@capacitor/device'
import { Preferences } from '@capacitor/preferences'
import { getCampusId as getCampusID } from './BaseController'
import { removeAllStoredItems } from '@/services/utils'

async function isWebPlatform() {
  const { platform } = await Device.getInfo()
  return platform === 'web'
}

const getBaseUrl = async () => {
  let envConfig = await loadConfig()
  return envConfig.backboneUrl
}
const getApiKey = async () => {
  let envConfig = await loadConfig()
  return envConfig.apiKey
}
const getFileUrl = async () => {
  let envConfig = await loadConfig()
  return envConfig.fileUrl
}
const getFileApiKey = async () => {
  let envConfig = await loadConfig()
  return envConfig.fileApiKey
}

async function storeData(key, data) {
  if (data instanceof Blob) {
    // Use IndexedDB for blobs
    await storeBlob(key, data)
  } else {
    // Use Flatted for other types of data
    const stringifiedData = Flatted.stringify(data)
    if (await isWebPlatform()) {
      localStorage.setItem(key, stringifiedData)
    } else {
      await Preferences.set({ key, value: stringifiedData })
    }
  }
}

async function getStoredData(key) {
  if (await isWebPlatform()) {
    const item = localStorage.getItem(key)
    return item ? Flatted.parse(item) : await retrieveBlob(key) // Try to get a blob if parsing fails
  } else {
    const { value } = await Preferences.get({ key })
    return value ? Flatted.parse(value) : await retrieveBlob(key)
  }
}

export const getUsers = async () => {
  const config = await getConfig()
  let baseUrl = await getBaseUrl()
  let customBase = 'users'
  customBase = baseUrl + customBase
  config['url'] = customBase
  try {
    const response = await axios(config)
    return response.data.items
  } catch (error) {
    console.error('Error fetching users:', error)
  }
}

const getCampusId = async () => {
  let campusId = await getCampusID()
  return campusId
  // const userProfile = await oAuthService.getUserProfile()
  // return userProfile['campus-id']
}

const oAuthService = useOAuthService()

const getConfig = async () => {
  const token = await oAuthService.getToken()

  return {
    method: 'GET',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'x-ps-api-key': await getApiKey(),
    },
  }
}

export const getLocks = async () => {
  const config = await getConfig()
  let baseUrl = await getBaseUrl()
  let campusId = await getCampusId()
  let customBase = 'locks'
  customBase = baseUrl + customBase
  config['url'] = customBase
  try {
    const response = await axios(config)
    const locks = response.data.items.filter(
      lock =>
        lock?.configs?.['blue-id-object-id'] &&
        lock['location']?.position &&
        lock['location']?.floorId &&
        lock['location']['campusId'] === campusId &&
        lock['serialNumber']
    )
    return locks
  } catch (error) {
    console.error('Error fetching locks:', error)
  }
}

export const getHardwareByType = async (buildingId, type) => {
  const storageKey = `hardware'${buildingId}'${type}`
  let hardwareData = await getStoredData(storageKey)
  if (!hardwareData) {
    const config = await getConfig()
    config.url = `${await getBaseUrl()}buildings/${buildingId}/hardwares/by-type?type=${type}`
    try {
      const response = await axios(config)
      hardwareData = response.data.items
      await storeData(storageKey, hardwareData)
    } catch (error) {
      console.error('Error fetching hardware:', error)
    }
  }
  return hardwareData
}

export const unregisterDeviceAccessControl = async deviceId => {
  const config = await getConfig()
  const baseUrl = await getBaseUrl()
  const body = JSON.stringify({ deviceId })
  config['url'] = `${baseUrl}access-control/blueid/device-registration`
  config['data'] = body
  config['method'] = 'DELETE'
  try {
    const response = await axios(config)
    return response
  } catch (error) {
    console.error('Error:', error)
    throw error
  }
}

export const syncAccessControlDeviceId = async deviceId => {
  const config = await getConfig()
  const baseUrl = await getBaseUrl()
  const body = JSON.stringify({ deviceId })
  config['url'] = `${baseUrl}access-control/blueid/device-registration`
  config['data'] = body
  config['method'] = 'POST'
  try {
    const response = await axios(config)
    return response
  } catch (error) {
    console.error('Error:', error)
    throw error
  }
}

export const doLogout = async () => {
  const config = await getConfig()
  const baseUrl = await getBaseUrl()
  config.url = `${baseUrl}session/logout`
  config['method'] = 'POST'
  try {
    const response = await axios(config)

    await oAuthService.performLogout()
    await removeAllStoredItems()
    window.location.href = '/'
    return response
  } catch (error) {
    console.error('Error:', error)
  }
}

export const getElevators = async buildingId => {
  const storageKey = `elevatorsData_${buildingId}`
  let elevatorsData = await getStoredData(storageKey)
  if (!elevatorsData) {
    const config = await getConfig()
    config.url = `${await getBaseUrl()}buildings/${buildingId}/elevators`
    try {
      let response = await axios(config)
      elevatorsData = response.data.items
      await storeData(storageKey, elevatorsData)
    } catch (error) {
      console.error('Error fetching elevators:', error)
      throw error
    }
  }
  return elevatorsData
}
